
























































































































import { Component, Prop, Watch, Mixins, PropSync } from 'vue-property-decorator'
import GetStoreMixin from '@/mixins/GetStore'
import { projectNamespace } from '@store/project'
import { commonNamespace } from '@store/common'
import { ProjectActionTypes } from '@store/project/Types'
import { Role } from '@store/common/Interface'
import { ElForm } from 'element-ui/types/form'
import { LegalPerson, NaturalPerson } from '@/store/users/interfaces'
import { Paginate, RequestParams } from '@/store/interfaces'
import { namespace } from 'vuex-class'
import { UsersActions } from '@/store/users/types'
const NSUsers = namespace('usersModule')

interface Form {
  projectId: number | null
  fullName: string
  position: string
  roleId: number | null
  files: any[]
  legalPersonId?: number | undefined
}

@Component({
  name: 'DialogEditIndividual',
})
export default class DialogEditIndividualComponent extends Mixins(GetStoreMixin) {
  @PropSync('visible', Boolean) private _visible!: boolean

  @Prop(Number) private legalPersonId!: number | undefined

  @Prop({
    type: Object,
    default: () => ({}),
  })
  private person!: NaturalPerson

  @Prop(Function) private fetchNaturalPersons!: (params?: RequestParams) => void

  @commonNamespace.State('rolesNatural')
  private rolesNatural!: Role[]

  @NSUsers.Action(UsersActions.A_FETCH_FILTERED_LEGAL_PERSONS)
  private fetchLegalPersons!: (params?: RequestParams) => Promise<Paginate<LegalPerson>>

  @projectNamespace.Action(ProjectActionTypes.A_EDIT_INDIVIDUAL)
  private editIndividualPerson!: (person: Form) => Promise<any>

  private isExpectation: boolean = false

  private form: Form = {
    projectId: null,
    fullName: '',
    position: '',
    roleId: null,
    files: [],
    legalPersonId: this.legalPersonId,
  }

  private legalPersons: LegalPerson[] = []

  private rules: any = {
    fullName: [{ required: true, message: 'Обязательное поле', trigger: 'blur' }],
    position: [{ required: true, message: 'Обязательное поле', trigger: 'blur' }],
  }

  @Watch('_visible')
  private changeVisible(val) {
    if (val) {
      this.form = {
        ...this.person,
        roleId: this.person.role?.id ?? null,
        projectId: this.selectedProject,
        legalPersonId: this.person.legalPerson?.id,
      }

      this.fetchLegalPersons({ projectId: this.selectedProject, count: 2, search: this.person.legalPerson?.name }).then(
        legalPersons => {
          this.legalPersons = legalPersons.data
        },
      )
    }
  }

  private created() {}

  // private mounted() {
  //   this.form = {
  //     ...this.person,
  //     projectId: this.selectedProject,
  //   }
  // }

  private searchLegalPersons(query: string) {
    this.fetchLegalPersons({ projectId: this.selectedProject, count: 5, search: query }).then(legalPersons => {
      this.legalPersons = legalPersons.data
    })
  }

  private handleEdit() {
    const form = this.$refs.form as ElForm

    form.validate(valid => {
      if (valid) {
        this.isExpectation = true
        this.editIndividualPerson(this.form)
          .then(data => {
            this.$notify({
              title: 'Выполнено',
              message: 'Действие успешно выполнено',
              type: 'success',
            })

            this.isExpectation = false
            this._visible = false

            form.resetFields()
          })
          .catch(error => {
            this.isExpectation = false
            this.$notify.error({
              title: 'Ошибка',
              message: error.response.data.message,
            })
          })
      }
    })
  }

  private handleAddFile(file, files) {
    this.form.files.push({ ...file })
  }

  private handleDelFile(uid) {
    const index = this.form.files.findIndex(file => file.uid === uid)
    this.form.files.splice(index, 1)
  }

  private handleClose() {
    const form = this.$refs.form as ElForm

    this._visible = false

    form.resetFields()
  }
}
